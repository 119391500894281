@import '../theme.scss';

#formCallback {
  background-color: $color-light-grey;
  padding: 50px $standard-padding-x;

  .mainContent {
    > .inner {
      max-width: 1350px;
      min-height: 500px;
      background-color: $color-white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      padding: 50px $standard-padding-x;
      align-content: space-between;

      .content {
        max-width: 750px;
        text-align: center;

        .title {
          color: $color-blue;
          font-size: clamp(22px, 3vw, 24px);
          font-weight: 600;
          width: auto;
          display: inline-block;

          &::after {
            width: 20%;
            display: block;
            content: '';
            background-color: $color-red-light;
            height: 2px;
            margin-top: 10px;
            border-bottom: unset;
            padding-top: 0;
          }
        }

        .intro {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
        }

        .form {
          max-width: 390px;
          margin-top: 20px;
          text-align: left;
          grid-gap: 20px;
          width: 100%;

          .phoneNumber {
            z-index: 5;
          }

          .info {
            font-size: 11px;
            letter-spacing: 0.02em;
            line-height: 1.25;
            color: $color-dark-grey;
            text-align: center;
            padding: 16px 1em 0 1em;
            margin: 0;
          }
        }

        .buttonContainer {
          margin-top: 50px;

          .buttons {
            grid-template-columns: auto auto;
            grid-gap: 20px;

            button {
              min-width: 190px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screenBreak-mobile) {
  #formCallback {
    padding-left: 0;
    padding-right: 0;

    .mainContent {
      > .inner {
        .content {
          .buttonContainer {
            width: 100%;
            max-width: 300px;

            .buttons {
              grid-template-columns: 1fr 1fr;
              grid-template-areas: 'previous next';

              .red {
                grid-area: next;
              }
              .white {
                grid-area: previous;
              }

              button {
                width: 100%;
                min-width: 120px;
              }
            }
          }
        }
      }
    }

    // .progressBar > .inner ul li {
    //   padding-left: 10px;
    //   padding-right: 10px;
    // }

    .progressBar {
      > .inner {
        ul {
          // grid-template-columns: repeat(5, auto);
          // justify-content: start;
          // translate: calc(-275px + (50vw - 137.5px));
          display: block;
          width: 1000px;
          font-size: 0;

          li {
            padding-left: 20px;
            padding-right: 20px;
            min-width: calc(100vw / 3);
            width: auto;
            display: inline-block;

            p .step {
              translate: 0 60px;
            }
          }
        }
      }
    }
  }
}
